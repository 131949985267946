import './styles/Benefits.css'; // Import CSS file for styling
import egg from '../assets/egg.png';
import protein from '../assets/protein.png';
import spinach from '../assets/spinach.png';
import iron from '../assets/iron.png';
import apple from '../assets/apple.png';
import fiber from '../assets/fiber.png';
import milk from '../assets/milk.png';
import calcium from '../assets/calcium.png';

const Benefits = () => {
  return (
    <section className="benefits-container">
      <h2>BENEFITS</h2>
      <h3>Nutritional Benefits Per Serving (40gm) </h3>
      <div className='benefits-body-container'>
        <div className="benefit">
            <img src={protein} alt="Protein" />
            <img src={egg} alt="Egg" />
            <h3>Protein = 2 farm eggs</h3>
        </div>
        <div className="benefit">
            <img src={iron} alt="Iron" />
            <img src={spinach} alt="Spinach" />
            <h3>Iron = 2.5 bowls of spinach</h3>
        </div>
        <div className="benefit">
            <img src={fiber} alt="Fiber" />
            <img src={apple} alt="Apple" />
            <h3>Dietary fiber = 24 apples</h3>
        </div>
        <div className="benefit">
            <img src={calcium} alt="Calcium" />
            <img src={milk} alt="Milk" />
            <h3>Calcium = 1 glass of milk</h3>
        </div>
      </div>

    </section>
  );
};

export default Benefits;