import HeroImage from '../assets/sattu-hero-main.jpg';
const Hero = () => {
    return (
        <section className="hero">
        <img src={HeroImage} alt="main-image"/>
        <div className="container">
            <h1 className="hero-txt-1">Fuel Your Day with SattuBrew </h1>
            <h2 className="hero-txt-2"> Your Daily Protein Fix </h2>
        </div>
    </section>
    )
}

export default Hero;