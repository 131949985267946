import React, { useState } from 'react';
import './styles/FAQAccordion.css';

const FAQAccordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: 'What ingredients do you use in SattuBrew?',
      answer: 'We use high-quality organic ingredients including sattu, spices, and natural sweeteners.',
    },
    {
      question: 'Is SattuBrew suitable for vegans?',
      answer: 'Yes, SattuBrew is 100% vegan as it contains no animal products or by-products.',
    },
    // Add more FAQ items here
    {
      question: 'How can I purchase SattuBrew?',
      answer: 'You can purchase SattuBrew online through our website or from select retail stores.',
    },
    {
      question: 'Is SattuBrew gluten-free?',
      answer: 'Yes, SattuBrew is gluten-free and suitable for individuals with gluten intolerance.',
    },
    {
      question: 'What is the shelf life of SattuBrew?',
      answer: 'The shelf life of SattuBrew is 9-12 months when stored in a cool, dry place.',
    },
    {
      question: 'Can I drink SattuBrew every day?',
      answer: 'Yes, SattuBrew is a healthy beverage and can be consumed daily as part of a balanced diet.',
    },
    {
      question: 'Is SattuBrew suitable for children/elderly?',
      answer: 'Yes, SattuBrew is made with natural ingredients, it can be consumed by everyone safely',
    },
  ];

  return (
    <section className="faq-section">
      <div className="faq-container">
        <h2>Frequently Asked Questions</h2>
        <div className="faq-accordion">
          {faqData.map((faq, index) => (
            <div className="faq-item" key={index}>
              <div className={`question ${activeIndex === index ? 'active' : ''}`} onClick={() => toggleAccordion(index)}>
                {faq.question}
                <div className="toggle-icon">{activeIndex === index ? '-' : '+'}</div>
              </div>
              {activeIndex === index && (
                <div className="answer">{faq.answer}</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQAccordion;
