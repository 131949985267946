import LemonCard from '../assets/LemonCard.jpg';
import JalJeeraCard from '../assets/JalJeeraCard.jpg';
import MintMoringaCard from '../assets/MintMoringaCard.jpg';
const Products = () => {
return(
    <section id="products" className="products">
    <div className="container">
        <h2>Products</h2>
        <div className="listWrapper">
            <div className="card">
                <img src={LemonCard} alt="Lemon SattuBrew"/>
            </div>
            <div className="card">
                <img src={MintMoringaCard} alt="Mint Moringa SattuBrew"/>
            </div>
            <div className="card">
                <img src={JalJeeraCard} alt="JalJeera SattuBrew"/>
            </div>
        </div>   
    </div>
</section>
)
}

export default Products;