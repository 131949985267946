const Footer = () => {
    return(
        <footer id="company" className="footer">
        <div className="container">
            <div className="contact-info">
                <h3>Office Address</h3>
                <p>HSR Layout, Sector 2, Bengaluru</p>
                <p>Email: <strong>sattubrew@gmail.com</strong></p>
                <p>Phone: <strong>+91-9661113455</strong></p>
            </div>
            <div className="company-info">
                <h3>Company Info</h3>
                <p><strong>Sattu Brew</strong></p>
                <p>Healthy Habit Dream Foods Pvt. Ltd. </p>
                <p>FSSAI NO. - <strong>21224190000110</strong></p>
            </div>
        </div>
    </footer>
    )
}

export default Footer;