import './styles/Team.css'
import Team1 from '../assets/Team1.jpg'
import Team2 from '../assets/Team2.jpg'
const Team = () => {
    return(
        <section id="team" className="team-section">
    <div className="container">
        <h2>Our Team</h2>
        <div className="team-members">
        <div className="team-member">
                <div className="team-card">
                    <img src={Team1} alt="Shashank"/>
                    <div className="team-info">
                        <h3>Jaspreet Saluja</h3>
                        <p>Co-Founder</p>
                    </div>
                </div>
            </div>
            <div className="team-member">
                <div className="team-card">
                    <img src={Team2} alt="Gaurav"/>
                    <div className="team-info">
                        <h3>Gaurav Singh</h3>
                        <p>Co-Founder</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    )
}
export default Team;