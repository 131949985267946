import './styles/OurStory.css'
import OurStoryImage from '../assets/OurStory1.png'
const OurStory = () => {
    return(
        <section id="our-story" className="our-story">
            <div className="container">
                <h2>Our Journey: Crafting SattuBrew</h2>
                <div className="image-wrapper">
                        <img src={OurStoryImage} alt="SattuBrew Story"/>
                </div>
                <div className="story-content">
                    <ul>
                        <li>In the heart of India, where ancient wisdom meets modern innovation, the story of SattuBrew unfolds. It's a tale of passion, dedication, and a relentless pursuit of wellness.</li>
                        <li>At SattuBrew, we believe in the power of nature's bounty. Our journey began with a simple desire — to create a protein drink that nourishes the body and feeds the soul.</li>
                        <li>We carefully select the finest ingredients, ensuring each sip is a celebration of purity and authenticity. From the earthy richness of roasted chickpeas to the vibrant zest of Indian flavors, every element of SattuBrew is a testament to our commitment to quality.</li>
                        <li>SattuBrew is more than just a drink; it's a lifestyle. It's about embracing wellness in its purest form, nurturing the body, and invigorating the spirit.</li>
                        <li>Our journey is one of discovery, connection — to the land, to the people, and to ourselves. As we raise our glasses to toast the past, present, and future, we invite you to join us on this extraordinary adventure.</li>
                    </ul>

                </div>
            </div>
        </section>
    )
}

export default OurStory;
