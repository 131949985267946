import logo from '../assets/SattuBrew_Logo.png';
const Header = () => {
    return(
        <header>
        <div className="container">
            <div className="imageWrapper">
                <img src={logo} alt="SattuBrew Logo"/>
                <strong>&nbsp;Sattu BREW</strong>
            </div>
            <nav>
                <ul>
                    <li><a href="#products">Products</a></li>
                    <li><a href="#company">Contact</a></li>
                </ul>
            </nav>
        </div>
    </header>
    )
}

export default Header;