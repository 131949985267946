import Header from './components/Header';
import Hero from './components/Hero';
import Products from './components/Products';
import Footer from './components/Footer';
import OurStory from './components/OurStory';
import Team from './components/Team';
import Testimonials from './components/Testimonials';
import Benefits  from './components/Benefits';
import FAQAccordion from './components/FAQAccordion';

import './App.css';

function App() {
  return (
    <div className="App">
      <Header/>
      <Hero/>
      <Products/>
      <OurStory/>
      <Benefits/>
      <Team/>
      <Testimonials/>
      <FAQAccordion/>
      <Footer/>
    </div>
  );
}

export default App;
