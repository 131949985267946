import React, { useState, useEffect } from 'react';
import './styles/Testimonials.css'; // Import your CSS file
import user1 from '../assets/user1.jpg'
import user2 from '../assets/user2.jpg'
import user3 from '../assets/user3.jpg'
import user4 from '../assets/user4.jpg'

const Testimonials = () => {
    const testimonialsData = [
        {
            id: 1,
            image: user4,
            text: "SattuBrew has been a delightful addition to my mornings! Its natural ingredients and authentic Indian flavors give me the boost I need to start my day.",
            author: "- Radha Patel, Ahmedabad"
        },
        {
            id: 2,
            image: user2,
            text: "I'm loving the taste and health benefits of SattuBrew! As a health-conscious individual, it's reassuring to have a protein drink that's sugar-free and loaded with nutrients.",
            author: "- Rajesh Kumar, Chennai"
        },
        {
            id: 3,
            image: user3,
            text: "SattuBrew is my go-to drink after workouts! Its refreshing flavors and high protein content make it an essential part of my fitness routine.",
            author: "- Priya Singh, Mumbai"
        },
        {
            id: 4,
            image: user1,
            text: "I'm impressed by the quality and taste of SattuBrew! It's a guilt-free indulgence that keeps me energized and satisfied throughout the day.",
            author: "- Arjun Gupta, Delhi"
        }
    ];

    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex(prevIndex => (prevIndex === testimonialsData.length - 1 ? 0 : prevIndex + 1));
        }, 5000); // Change testimonials every 5 seconds

        return () => clearInterval(interval);
    }, []);

    return (
        <section id="testimonials" className="testimonials-section">
            <div className="container">
                <h2>Testimonials</h2>
                <div className="testimonial-carousel">
                    {testimonialsData.map((testimonial, index) => (
                        <div key={testimonial.id} className={`testimonial ${index === activeIndex ? 'active' : ''}`}>
                            <img src={testimonial.image} alt={`Testimonial ${testimonial.id}`} />
                            <p>{testimonial.text}</p>
                            <span className="author">{testimonial.author}</span>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Testimonials;
